import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const PageWrapper = styled.div`
  padding: 12rem 2.4rem;
  background: var(--color-surface-light);
  border-radius: var(--border-radius-surface);
`;

const PolicyWrapper = styled.div`
  width: 100%;
  max-width: var(--page-content-width);
  margin: 0 auto;
  h1 {
    margin-bottom: 1.6rem;
    color: var(--color-heading-dark);
  }
  h2 {
    margin: 4.8rem 0 1.6rem;
    color: var(--color-heading-dark);
  }
  h3 {
    margin: 3.2rem 0 1.6rem;
  }
  h4 {
    margin: 2.4rem 0 1.6rem;
  }
  /* p {
    margin-bottom: 1.6rem;
  } */
  a {
    position: relative;
    font-weight: bold;
    color: var(--color-body-dark);
    transition: opacity 0.15s ease-in-out;
    ::after {
      position: absolute;
      content: '';
      display: block;
      bottom: -0.2rem;
      width: 100%;
      height: 0.2rem;
      background: #fff;
      transform: translateY(0);
      opacity: 0;
      transition: all 0.15s ease-in-out;
    }
    :hover ::after {
      opacity: 1;
      transform: translateY(0.2rem);
    }
  }
  ul {
    font-size: 1.8rem;
    line-height: 1.7;
  }

  li > ul {
    margin-left: 2rem;
  }
`;

const CookiePolicy = () => {
  const [policy, setPolicy] = useState('');
  useEffect(() => {
    // get data from GitHub api
    fetch(`https://www.iubenda.com/api/privacy-policy/59665527/cookie-policy/no-markup`)
      .then(response => response.json())
      .then(resultData => {
        console.log(resultData);
        setPolicy(resultData.content);
      }) 
  }, [])

  return (
    <PageWrapper>
      <PolicyWrapper dangerouslySetInnerHTML={{__html: policy}} />
    </PageWrapper>
  )
}

export default CookiePolicy;